import React from "react";
import Head from "next/head";

import heroImg from "@assets/images/layout/layout-hero-1440.jpg";
import classNames from "classnames";
import { isNullOrUndefined } from "@app/utilities/helpers";

type ILayoutV1Props = {
  title?: string;
  tagline?: string;
  children: React.ReactElement | React.ReactElement[];
  fluidContainer?: boolean;
  noPadding?: boolean;
};

const PageLayoutV1: React.FC<ILayoutV1Props> = ({ title, tagline, children, fluidContainer, noPadding }) => {
  const isContainerFluid = isNullOrUndefined(fluidContainer) ? false : fluidContainer,
    containerClasses = classNames("layout-container", {
      container: !isContainerFluid,
      "no-padding": !!noPadding,
    });

  return (
    <div className="layout-v1" data-testid="layout-v1">
      <Head>
        <link rel="preload" as="image" href={heroImg.src} />
      </Head>
      <div className="layout-hero">
        <div className="title-container">
          <h1 className="title">{title || "Heritage Consulting"}</h1>
          {tagline ? <p className="tagline">{tagline}</p> : <></>}
        </div>
      </div>
      <div className={containerClasses}>{children}</div>
    </div>
  );
};

export default PageLayoutV1;
