import React from "react";
import Link from "next/link";
import Head from "next/head";
import PageLayoutV1 from "@app/hocs/PageLayoutV1";

const NotFound: React.FC = () => {
  return (
    <PageLayoutV1 title="404" tagline="Page Not Found">
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <div className="text-center">
        <p>
          Take me <Link href="/">Home</Link>
        </p>
      </div>
    </PageLayoutV1>
  );
};

export default NotFound;
